import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Logo from '../../../components/Logo';
import { Container } from './styles';

const SimpleHeader = ({ logo }) => {
  return (
    <Container>
      <Link to="/">
        {logo ? <img src={logo.fluid.src} alt="Logo" style={{ width: 'auto', height: '60px' }} /> : <Logo />}
      </Link>
    </Container>
  );
};

SimpleHeader.propTypes = {
  logo: PropTypes.object,
};

SimpleHeader.defaultProps = {
  logo: null,
};
export default SimpleHeader;
