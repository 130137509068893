import React, { Fragment, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import parse from 'html-react-parser';
import Image from '../Image';
import { Container, Row, Col } from '../ui/Wrapper';
import SectionTitle from '../ui/section-title';
import AccordionWrap from '../ui/accordion';
import VideoButton from '../ui/video-button';
import ModalVideo from '../ui/modal-video';
import Button from '../ui/button';
import {
  AboutAreaWrap,
  AboutTextBox,
  AboutImageBox,
  ImageBox1,
  ImageBox2,
  ImageBox3,
  ImageBox4,
  SectionBottom,
  MainImageBox,
  VideoBtnWrap,
} from './styles';
import useSiteLocation from '../../hooks/useSiteLocation';

const TestimonialVideo = ({
  sectionTitleStyle,
  buttonOneStyle,
  buttonTwoStyle,
  title,
  subtitle,
  action,
  secondaryAction,
  videoItems,
  noPadding,
}) => {
  const { buildPath } = useSiteLocation();
  const [videoOpen, setVideoOpen] = useState(false);
  const modalVideoOpen = () => {
    setVideoOpen(true);
  };

  const modalVideoClose = () => {
    setVideoOpen(false);
  };

  const columns = videoItems.length === 1 ? 12 : 6;

  videoItems.forEach(item => {
    [, item.videoId] = item.videoLink.split('=', -1);
    [, item.videoChannel] = item.videoLink.split('.');
  });

  return (
    <>
      <AboutAreaWrap noPadding={noPadding}>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle {...sectionTitleStyle} subtitle={subtitle} title={title} />
            </Col>
          </Row>
          <Row alignitems="center" className="justify-content-center">
            {videoItems.map(item => (
              <Col lg={columns}>
                <AboutImageBox>
                  <MainImageBox>
                    <Image fluid={item.videoImage} alt="About Banner" />
                    {item.videoLink && (
                      <VideoBtnWrap>
                        <VideoButton skin="primary" onClick={modalVideoOpen} />
                      </VideoBtnWrap>
                    )}
                  </MainImageBox>
                  <ModalVideo
                    channel={item.videoChannel}
                    videoId={item.videoId}
                    isOpen={videoOpen}
                    onClose={modalVideoClose}
                  />
                </AboutImageBox>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={12}>
              <SectionBottom className="text-center">
                {action && (
                  <Button
                    as={Link}
                    to={buildPath(action.link)}
                    target="_blank"
                    bgcolor={action.bgcolor}
                    color={action.color}
                    {...buttonOneStyle}
                  >
                    {action.label}
                  </Button>
                )}
                {secondaryAction && (
                  <Button as={Link} to={secondaryAction.link} target="_blank" {...buttonTwoStyle}>
                    {secondaryAction.label}
                  </Button>
                )}
              </SectionBottom>
            </Col>
          </Row>
        </Container>
      </AboutAreaWrap>
    </>
  );
};

TestimonialVideo.propTypes = {
  sectionTitleStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    bgcolor: PropTypes.string,
    color: PropTypes.string,
  }),
  secondaryAction: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  videoItems: PropTypes.arrayOf(
    PropTypes.shape({
      videoLink: PropTypes.string,
      videoImage: PropTypes.object,
    })
  ).isRequired,
  noPadding: PropTypes.bool,
  buttonOneStyle: PropTypes.object,
  buttonTwoStyle: PropTypes.object,
};

TestimonialVideo.defaultProps = {
  subtitle: null,
  action: null,
  secondaryAction: null,
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  noPadding: false,
  buttonOneStyle: {
    m: '10px',
    hover: '2',
  },
  buttonTwoStyle: {
    m: '10px',
    varient: 'outlined',
  },
};

export default TestimonialVideo;
