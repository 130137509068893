import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../../components/seo';
import Hero from '../../components/hero';
import Footer from '../Layout/Footer';
import ContactArea from '../contact-area';
import PreparationCourseFaq from '../../components/PreparationCourseFaq';
import Benefits from '../Benefits';
import TeachersList from '../../components/TeachersList';
import { device } from '../../theme';
import TestimonialVideo from '../../components/TestimonialVideo';
import TestimonialSection from '../testimonial-area';
import ProductWP from '../../components/ProductWP';
import SimpleHeader from '../Layout/SimpleHeader';
import DemonstrationVideo from '../../components/DemonstrationVideo/DemonstrationVideo';
import Header from '../Layout/Header';

const CourseSalesCapture = ({ location, data }) => {
  const {
    logo,
    showMenu,
    heroTitle,
    heroSubtitle,
    heroDescription,
    heroImage,
    heroActionLink,
    heroActionLabel,
    heroActionColor,
    heroActionFontColor,
    heroBackground,
    heroMobileBackground,
    demonstrationVideoTitle,
    demonstrationVideoSubtitle,
    demonstrationVideoActionLink,
    demonstrationVideoActionLabel,
    demonstrationVideoActionColor,
    demonstrationVideoActionFontColor,
    demonstrationVideoImage,
    demonstrationVideoItems,
    benefitTitle,
    benefitSubtitle,
    benefitActionLabel,
    benefitActionLink,
    benefitActionColor,
    benefitActionFontColor,
    benefitItems,
    testimonialTitle,
    testimonialSubtitle,
    testimonialItems,
    testimonialVideoTitle,
    testimonialVideoSubtitle,
    testimonialVideoActionLink,
    testimonialVideoActionLabel,
    testimonialVideoActionColor,
    testimonialVideoActionFontColor,
    testimonialVideoItems,
    teacherItems,
    productTitle,
    productSubtitle,
    productTagWP,
    faqTitle,
    faqSubtitle,
    faqItems,
  } = data;

  const backgroundImage = [
    heroMobileBackground.fluid,
    {
      ...heroBackground.fluid,
      media: device.smallOnly,
    },
  ];

  const heroAction = {
    label: heroActionLabel,
    link: heroActionLink,
    bgcolor: heroActionColor?.value,
    color: heroActionFontColor,
  };

  const demonstrationVideoAction = {
    label: demonstrationVideoActionLabel,
    link: demonstrationVideoActionLink,
    bgcolor: demonstrationVideoActionColor?.value,
    color: demonstrationVideoActionFontColor,
  };

  const benefitsAction = {
    label: benefitActionLabel,
    link: benefitActionLink,
    bgcolor: benefitActionColor?.value,
    color: benefitActionFontColor,
  };

  const testimonialVideoAction = {
    label: testimonialVideoActionLabel,
    link: testimonialVideoActionLink,
    bgcolor: testimonialVideoActionColor?.value,
    color: testimonialVideoActionFontColor,
  };

  const testimonialFilters = testimonialItems.map(item => {
    return item.slug;
  });

  const teacherFilters = teacherItems.map(item => {
    return item.slug;
  });

  const benefitFilters = benefitItems.map(item => {
    return item.slug;
  });

  return (
    <>
      <SEO title={heroTitle} description={heroDescription} location={location} image={heroImage.fluid.src} />
      {showMenu ? <Header logo={logo} /> : <SimpleHeader logo={logo} />}
      <Hero
        title={heroTitle}
        subtitle={heroSubtitle}
        description={heroDescription}
        action={heroAction}
        image={heroImage}
        backgroundImage={backgroundImage}
        dark
      />
      <DemonstrationVideo
        title={demonstrationVideoTitle}
        subtitle={demonstrationVideoSubtitle}
        action={demonstrationVideoAction}
        backgroundImage={demonstrationVideoImage}
        videoItems={demonstrationVideoItems}
      />
      <Benefits
        title={benefitTitle}
        subtitle={benefitSubtitle}
        primaryAction={benefitsAction}
        itemsFilter={benefitFilters}
      />
      <TestimonialSection title={testimonialTitle} subtitle={testimonialSubtitle} itemsFilter={testimonialFilters} />
      <TestimonialVideo
        title={testimonialVideoTitle}
        subtitle={testimonialVideoSubtitle}
        action={testimonialVideoAction}
        videoItems={testimonialVideoItems}
      />
      <TeachersList itemsFilter={teacherFilters} />
      <ProductWP title={productTitle} subtitle={productSubtitle} tag={productTagWP} />
      <PreparationCourseFaq title={faqTitle} subtitle={faqSubtitle} items={faqItems} />
      <ContactArea />
      <Footer onlyBottom={!showMenu} />
    </>
  );
};

CourseSalesCapture.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    logo: PropTypes.object,
    showMenu: PropTypes.bool.isRequired,
    heroTitle: PropTypes.string.isRequired,
    heroSubtitle: PropTypes.string.isRequired,
    heroDescription: PropTypes.string.isRequired,
    heroActionLabel: PropTypes.string.isRequired,
    heroActionLink: PropTypes.string.isRequired,
    heroActionColor: PropTypes.object,
    heroActionFontColor: PropTypes.string,
    heroImage: PropTypes.object.isRequired,
    heroBackground: PropTypes.object.isRequired,
    heroMobileBackground: PropTypes.object.isRequired,
    demonstrationVideoTitle: PropTypes.string.isRequired,
    demonstrationVideoSubtitle: PropTypes.string.isRequired,
    demonstrationVideoActionLink: PropTypes.string.isRequired,
    demonstrationVideoActionLabel: PropTypes.string.isRequired,
    demonstrationVideoActionColor: PropTypes.object,
    demonstrationVideoActionFontColor: PropTypes.string,
    demonstrationVideoImage: PropTypes.object.isRequired,
    demonstrationVideoItems: PropTypes.object.isRequired,
    benefitTitle: PropTypes.string.isRequired,
    benefitSubtitle: PropTypes.string.isRequired,
    benefitActionLabel: PropTypes.string.isRequired,
    benefitActionLink: PropTypes.string.isRequired,
    benefitActionColor: PropTypes.object,
    benefitActionFontColor: PropTypes.string,
    benefitItems: PropTypes.array.isRequired,
    testimonialTitle: PropTypes.string.isRequired,
    testimonialSubtitle: PropTypes.string.isRequired,
    testimonialItems: PropTypes.array.isRequired,
    testimonialVideoTitle: PropTypes.string.isRequired,
    testimonialVideoSubtitle: PropTypes.string.isRequired,
    testimonialVideoActionLink: PropTypes.string.isRequired,
    testimonialVideoActionLabel: PropTypes.string.isRequired,
    testimonialVideoActionColor: PropTypes.object,
    testimonialVideoActionFontColor: PropTypes.string,
    testimonialVideoItems: PropTypes.array.isRequired,
    teacherItems: PropTypes.array.isRequired,
    productTitle: PropTypes.string.isRequired,
    productSubtitle: PropTypes.string.isRequired,
    productTagWP: PropTypes.string.isRequired,
    faqTitle: PropTypes.string.isRequired,
    faqSubtitle: PropTypes.string.isRequired,
    faqItems: PropTypes.array.isRequired,
  }).isRequired,
};

export default CourseSalesCapture;
